<template>
    <div v-if="documents.length > 0" class="container content-space-1">
        <div class="rich-text mb-4">
            <h2 v-if="$validateText(slice.primary.h2)">{{ $prismic.asText(slice.primary.h2) }}</h2>
            <div v-if="$validateText(slice.primary.teaser)" v-html="$prismic.asHtml(slice.primary.teaser)" />
        </div>
        <div class="row gy-4">
            <div class="col-12 col-md-6">
                <highlighted-document-item :document="documents[0]" :is-highlighted="true" />
            </div>
            <div v-if="otherDocuments.length > 0" class="col-12 col-md-6 d-flex flex-column gap-4 gap-md-2">
                <highlighted-document-item v-for="document in otherDocuments" :key="document.id" :document="document" />
            </div>
        </div>
    </div>
</template>

<script>
import HighlightedDocumentItem from '~/components/HighlightedDocumentItem.vue';
import { replace } from '@swegaming-ab/nuxtjs-helpers';
export default {
    components: {
        HighlightedDocumentItem
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            documents: []
        };
    },
    async fetch() {
        const response = await this.$prismic.api.getByIDs(
            this.documentIds,
            { lang: process.env.PRISMIC_LOCALE }
        );
        const documents = replace(response.results);
        this.documents = documents;
    },
    computed: {
        documentIds() {
            return [1, 2, 3, 4]
                .map(num => this.slice.primary[`document_${num}`]?.id)
                .filter(Boolean);
        },
        otherDocuments() {
            return this.documents.slice(1);
        }
    },
};
</script>