import { pick } from 'lodash';
export default {
    computed: {
        categories() {
            if (this.$isSweden()) {
                return this.swedenCategories;
            }
            if (this.$isNorway()) {
                return this.norwayCategories;
            }
            if (this.$isSpain()) {
                return this.spainCategories;
            }
            if (this.$isDenmark()) {
                return this.denmarkCategories;
            }
            return {};
        },
        swedenCategories() {
            return pick(this.allCategories, [
                'interest',
                'amount',
                'duration',
                'withoutUC',
                'directPayment',
                'paymentRemarks',
            ]);
        },
        norwayCategories() {
            return pick(this.allCategories, [
                'interest',
                'amount',
                'minAge',
                'paymentRemarks'
            ]);
        },
        spainCategories() {
            this.activeCategory = 'amount';
            return pick(this.allCategories, [
                'amount',
                'asnef',
                'minAge',
            ]);
        },
        denmarkCategories() {
            return pick(this.allCategories, [
                'interest',
                'amount',
                'minAge'
            ]);
        },
        allCategories() {
            return {
                interest: {
                    styling: 'bar',
                    title: this.$translate('interest', 'Ränta'),
                    maximumDataValue: this.maximumDataValue('maxInterestPossible'),
                    minStringFunction: (model) => model.minInterestPossibleString,
                    maxStringFunction: (model) => model.maxInterestPossibleString,
                    minDataKey: 'minInterestPossible',
                    maxDataKey: 'maxInterestPossible',
                },
                amount: {
                    styling: 'bar',
                    title: this.$translate('calculator_loan_amount', 'Lånebelopp'),
                    maximumDataValue: this.maximumDataValue(this.alternateDataKeys.maxAmount),
                    minStringFunction: (model) => model[this.alternateDataKeys.minAmountString],
                    maxStringFunction: (model) => model[this.alternateDataKeys.maxAmountString],
                    minDataKey: this.alternateDataKeys.minAmount,
                    maxDataKey: this.alternateDataKeys.maxAmount,
                },
                duration: {
                    styling: 'bar',
                    title: this.$translate('loan_duration', 'Löptid'),
                    maximumDataValue: this.maximumDataValue(this.alternateDataKeys.maxDuration),
                    minStringFunction: (model) => model[this.alternateDataKeys.getMinDurationString](this.$translate('month_short', 'mån'), this.$translate('year_short', 'år')),
                    maxStringFunction: (model) => model[this.alternateDataKeys.getMaxDurationString](this.$translate('month_short', 'mån'), this.$translate('year_short', 'år')),
                    minDataKey: this.alternateDataKeys.minDuration,
                    maxDataKey: this.alternateDataKeys.maxDuration,
                },
                minAge: {
                    styling: 'icon',
                    title: this.$translate('min_age', 'Åldersgräns'),
                    iconDisabled: true,
                    getDataValue: () => null,
                    getDataString: (model) => model.minAge + ' ' + this.$translate('years', 'år'),
                },
                withoutUC: {
                    styling: 'icon',
                    title: this.$translate('without_uc', 'Utan UC'),
                    getDataValue: (model) => !model.takesUC,
                    getDataString: (model) => {
                        return this.$translate('uses', 'Använder') + ' ' + this.$getCreditCheckString(model.creditCheck);
                    }
                },
                directPayment: {
                    styling: 'icon',
                    title: this.$translate('direct_pay_out', 'Direktutbetalning'),
                    getDataValue: (model) => model.hasDirectPayments,
                    getDataString: (model) => {
                        if (model.hasDirectPayments) {
                            return this.$translate('direct_pay_out', 'Direktutbetalning') + (
                                this.$getDirectPaymentToBanksString(model) 
                                    ? ` (${this.$getDirectPaymentToBanksString(model)})`
                                    : ''
                            );
                        }
                        return this.$translate('structured_data_no_direct_payment', 'Utan direktutbetalning');
                    }
                },
                paymentRemarks: {
                    styling: 'icon',
                    title: this.$translate('payment_remarks', 'Betalningsanmärkningar'),
                    getDataValue: (model) => model.acceptsPaymentRemarks,
                    getDataString: (model) => {
                        if (model.acceptsPaymentRemarks) {
                            return this.$translate('structured_data_accepts_payment_remarks', 'Accepterar betalningsanmärkningar') + ' ' +
                                (
                                    model.paymentRemarksMaxCount ? 
                                        `(${this.$translate('structured_data_max_payment_remarks', 'Max {amount} st')
                                            .replace('{amount}', model.paymentRemarksMaxCount)})` 
                                        : ''
                                );
                        }
                        return this.$translate('structured_data_does_not_accept_payment_remarks', 'Accepterar inte betalningsanmärkningar');
                    }
                },
                asnef: {
                    styling: 'icon',
                    title: this.$translate('with_asnef', 'With ASNEF'),
                    getDataValue: (model) => model.acceptsASNEF,
                    getDataString: (model) => {
                        return model.acceptsASNEF ? this.$translate('yes', 'Ja') : this.$translate('no', 'Nej');
                    }
                },
            };
        }
    },
    methods: {
        maximumDataValue(category) {
            const maxValues = this.list.map(listItem => {
                return listItem.model[category];
            });
            return Math.max(...maxValues);
        }
    }
};