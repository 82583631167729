<template>
    <div class="card-body px-0 py-0 border-0 overflow-hidden">
        <div v-if="showAlternative" class="d-flex flex-column justify-content-center border-bottom mx-3 pb-3">
            <p class="pt-2 pb-0 fs-6">{{ alternativeText }}</p>
            <out-link 
                :document="alternative" class="btn btn-info text-white fw-bold" 
                placement="list-alternative"
            />
        </div>
        <div v-else class="row g-0">
            <div v-if="lenderOrCard.data.redirect_enabled" class="col order-2 order-md-1">
                <div class="cta-orange d-flex justify-content-center">
                    <out-link 
                        :document="lenderOrCard" class="col-12 btn py-3 text-white fw-bold" 
                        placement="list"
                        :position="position" 
                    />
                </div>
                <span v-if="$isNorway()" class="text-secondary d-block text-center fs-6 mt-2">Annonslenke</span>
            </div>
            <div v-else-if="alternative" class="col pb-2 order-2 order-md-1">
                <div class="cta-orange d-flex justify-content-center">
                    <a role="button" class="col-12 btn py-3 text-white fw-bold" @click="showAlternative = true;">
                        {{ $translate('cta_to_application', 'Till ansökan') }}
                    </a>
                </div>
            </div>
            <div class="col order-1 order-md-2">
                <nuxt-link :to="$prismic.linkResolver(lenderOrCard)" class="col-12 btn py-3 text-dark fw-bold">{{ $translate('card_read_more', 'Läs mer') }}</nuxt-link>
            </div>
        </div>
    </div>
</template>
<script>
import GetTemplatedString from '@/mixins/GetTemplatedString.js';
export default {
    mixins: [
        GetTemplatedString
    ],
    props: {
        lenderOrCard: {
            type: Object,
            required: true
        },
        position: {
            type: Number,
            required: false,
            default: null
        }
    },
    data() {
        return {
            showAlternative: false,
        };
    },
    computed: {
        alternative() {
            if (this.lenderOrCard.data.alternative?.id) {
                return this.$store.state.lenders[this.lenderOrCard.data.alternative.id];
            }

            return null;
        },
        alternativeText() {
            return this.getTemplatedString(
                this.alternative.data.alternative_text,
                this.alternative
            );
        }
    },
};
</script>