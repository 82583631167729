<template>
    <div>
        <slices
            :slices="document.data.body"
            :document="document"
        />
        <authority-box 
            :author="document.data.author" 
            :editor="document.data.editor"
            :last-publication-date="document.last_publication_date"
            :sources="document.data.sources"
        />
        <newsletter-form v-if="!hasNewsletterSlice" />
        <site-wide-links 
            :footer-id="document.data.footer_id" 
        />
    </div>
</template>

<script>
import Slices from '@/components/slices/Slices.vue';
import AuthorityBox from '@/components/AuthorityBox.vue';
import NewsletterForm from '@/components/newsletter-form/index.vue';

export default {
    components: { 
        AuthorityBox,
        Slices,
        NewsletterForm
    },
    props: {
        document: {
            type: Object,
            required: true
        }
    },
    computed: {
        hasNewsletterSlice() {
            return this.document.data.body
                .some(slice => slice.slice_type === 'newsletter_form');
        }
    }
};
</script>
