<template>
    <div class="container content-space-1">
        <div class="row justify-content-center">
            <div class="col col-lg-9">
                <div 
                    v-if="$validateText(slice.primary.text)"
                    class="rich-text mb-4 text-dark"
                    v-html="$prismic.asHtml(slice.primary.text)"
                />
                <div class="overflow-hidden">
                    <div v-if="showFullscreenImage" class="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center px-2 px-md-5 zi-100">
                        <div class="w-100 w-md-fit-content position-absolute top-50 start-50 translate-middle d-flex justify-content-center zi-1">
                            <i class="bi bi-x-circle text-white fs-2 position-absolute bottom-100 end-0 translate-middle-x cursor-pointer" @click="showFullscreenImage = false" />
                            <prismic-image 
                                :img="activeImg"
                                :alt="activeImg.alt"
                                :w="imageWidth(activeImg)"
                                class="w-90 w-md-100 max-width-md-vw-90 max-height-vh-95 max-height-md-vh-85"
                            />
                        </div>
                        <div
                            class="position-fixed top-0 start-0 w-100 h-100 bg-dark opacity-50"
                            @click.stop="showFullscreenImage = false"
                        />
                    </div>
                    <div ref="swiper" class="w-100">
                        <div class="align-items-stretch h-100 swiper-wrapper">
                            <div 
                                v-for="(item, index) in items" 
                                :key="index" 
                                class="h-auto d-flex justify-content-center rounded opacity-50 transition-4 swiper-slide"
                                @click="selectImage(item.carousel_image)"
                            >
                                <div class="h-100">
                                    <div class="position-relative">
                                        <prismic-image 
                                            :img="item.carousel_image"
                                            :alt="item.carousel_image.alt"
                                            :w="imageWidth(item.carousel_image)"
                                            class="w-auto max-width-100"
                                        />
                                        <i class="bi bi-arrows-fullscreen d-none text-white bg-secondary justify-content-center align-items-center p-2 position-absolute bottom-0 end-0 translate-middle rounded-2 cursor-pointer" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/swiper-bundle.min.css';

export default {
    props: {
        slice: {
            type: Object,
            required: true
        },
    },
    data: () => ({
        activeImg: {},
        showFullscreenImage: false,
    }),
    computed: {
        items() {
            return this.slice.items
                .filter(item => item.carousel_image.url);
        },
    },
    mounted() {
        const swiper = new Swiper(this.$refs.swiper, {
            spaceBetween: 10,
            grabCursor: true,
            slidesPerView: 1.2,
            breakpoints: {
                600: {
                    slidesPerView: 1.5,
                },
            }
            
        });
        return swiper;
    },
    methods: {
        imageWidth(image) {
            return image.dimensions.width > 1000 ? '1000' : `${image.dimensions.width}`;
        },
        selectImage(payload) {
            const swiperInstance = this.$refs.swiper.swiper;
            if (swiperInstance.clickedIndex !== swiperInstance.activeIndex){
                swiperInstance.slideTo(swiperInstance.clickedIndex);
            }
            else {
                this.activeImg = payload;
                this.showFullscreenImage = true;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.swiper-slide-active {
    opacity: 1 !important;
    i {
        @media (min-width: 768px) {
            display: flex !important;
        }
    }
}
</style>