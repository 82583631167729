<template>
    <div class="row gy-5">
        <div class="col-12 col-md-4">
            <h2>{{ $translate('structured_data_comparisons_h2', 'Jämför lånegivarnas data') }}</h2>
            <div class="rich-text" v-html="teaser" />
        </div>
        <div class="col-12 col-md-8">
            <div class="bg-light p-2 mb-3 d-flex gap-2 rounded overflow-auto">
                <button
                    v-for="(categoryValue, categoryKey, index) in categories"
                    :key="index"
                    class="px-3 py-2 flex-grow-1 text-center text-nowrap shadow border border-light border-primary-hover rounded-hover transition-5 text-secondary"
                    :class="categoryKey === activeCategory ? 'bg-primary text-white rounded' : 'bg-white'"
                    @click="activeCategory = categoryKey"
                >
                    {{ categoryValue.title }}
                </button>
            </div>

            
            <div class="table-responsive card">
                <table 
                    v-for="(categoryData, categoryKey, categoryIndex) in categories" 
                    v-show="categoryKey === activeCategory"
                    :key="categoryIndex" 
                    class="table m-0 card-body"
                >
                    <tbody>
                        <tr v-for="(listItem, index) in list" :key="index">
                            <th class="border-end bg-light">
                                <nuxt-link :to="$prismic.linkResolver(listItem)" class="fw-bold">
                                    {{ listItem.data.title }}
                                </nuxt-link>
                            </th>
                            <table-data-bar 
                                v-if="categoryData.styling === 'bar'"
                                :model="listItem.model"
                                :category-data="categoryData"
                                :maximum-data-value="categoryData.maximumDataValue"
                            />
                            <table-data-with-icon
                                v-else-if="categoryData.styling === 'icon'"
                                :model="listItem.model"
                                :category-data="categoryData"
                            />
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import TableDataBar from './TableDataBar.vue';
import TableDataWithIcon from './TableDataWithIcon.vue';
import ComparisonCategories from './ComparisonCategories.js';
export default {
    components: {
        TableDataBar,
        TableDataWithIcon
    },
    mixins: [ComparisonCategories],
    props: {
        list: {
            type: Array,
            required: true
        },
        slice: {
            type: Object,
            required: true
        },
        alternateDataConfig: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },
    data() {
        return {
            activeCategory: 'interest',
        };
    },
    computed: {
        teaser() {
            if (this.$prismic.asText(this.slice.primary.comparisons_teaser)?.trim()) {
                return this.$prismic.asHtml(this.slice.primary.comparisons_teaser);
            }
            return this.$translate('structured_data_comparisons_teaser', 'Här kan du jämföra långivarnas räntor, lånebelopp, löptid med mera, och se hur det står sig mot varandra.');
        },
        alternateDataKeys() {
            return this.$getAlternateDataKeys(this.alternateDataConfig);
        },
    },
};
</script>