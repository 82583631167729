<template>
    <div class="row gy-5">
        <div class="col-12 col-md-4">
            <h2>{{ $translate('structured_data_stats_h2', 'Statistik') }}</h2>
            <div class="rich-text" v-html="teaser" />
        </div>
        <div class="col-12 col-md-8">
            <div class="table-responsive card">
                <table class="table m-0 card-body">
                    <tbody>
                        <tr v-for="(stat, index) in lenderStats" :key="index">
                            <th class="text-dark fw-bold border-end">
                                {{ stat.title }}
                            </th>
                            <td class="text-dark min-wpx-200">
                                {{ stat.data }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import StatisticsData from './StatisticsData.js';

export default {
    mixins: [StatisticsData],
    props: {
        list: {
            type: Array,
            required: true
        },
        slice: {
            type: Object,
            required: true
        },
        alternateDataConfig: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },
    computed: {
        teaser() {
            if (this.$prismic.asText(this.slice.primary.statistics_teaser)?.trim()) {
                return this.$prismic.asHtml(this.slice.primary.statistics_teaser);
            }
            return this.$translate('structured_data_stats_teaser', 'För att hjälpa dig att fatta rätt beslut finns här en sammaställning av långivarnas egenskaper.');
        },
        alternateDataKeys() {
            return this.$getAlternateDataKeys(this.alternateDataConfig);
        },
    },
};
</script>