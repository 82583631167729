export default {
    methods: {
        getTemplatedString(templateString, lender) {

            if (! templateString) {
                return '';
            }

            const model = new this.$models.Loan(lender);

            return templateString
                .replaceAll('{title}', lender.data.title)
                .replaceAll('{amountString}', model.amountString)
                .replaceAll('{minAmountString}', model.minAmountString)
                .replaceAll('{maxAmountString}', model.maxAmountString)
                .replaceAll('{durationString}', this.$getDurationString(model))
                .replaceAll('{interestString}', model.interestString)
                .replaceAll('{connectedBanks}', model.connectedBanks)
                .replaceAll('{minAge}', model.minAge)
                .replaceAll('{effectiveInterestString}', model.effectiveInterestString)
                .replaceAll('{debtCollectionAmountString}', model.amountDebtCollectionString)
                .replaceAll('{debtCollectionMinAmountString}', model.minAmountDebtCollectionString)
                .replaceAll('{debtCollectionMaxAmountString}', model.maxAmountDebtCollectionString)
                .replaceAll('{debtCollectionDurationString}', this.$getDurationString(model, 'debt_collection', 'override'));
        }
    }
};