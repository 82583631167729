<template>
    <div class="row gap-2 mt-4">
        <lender 
            v-for="lender in highlightedLenders" 
            :key="lender.id" 
            :lender="lender" 
            :alternate-data-config="alternateDataConfig" 
        />
    </div>
</template>

<script>
import Lender from '@/components/calculator/Lender.vue';

export default {
    components: {
        Lender
    },
    props: {
        duration: {
            type: Number,
            required: true
        },
        loanAmount: {
            type: Number,
            required: true
        },
        alternateDataConfig: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },
    computed: {
        orderedLenders() {
            const lendersWithPartnership = Object.values(this.$store.state.lenders)
                .filter(lender => lender.data.redirect_enabled && !lender.data.lists_disabled);

            return this.$getOrderedList(lendersWithPartnership, this.$store.state.lenders, this.$models.Loan);
        },
        filteredLenders() {
            const dataKeys = this.$getAlternateDataKeys(this.alternateDataConfig);
            const lenders = this.orderedLenders
                .filter(({model}) => {
                    if (this.isValueOutsideRange(this.loanAmount, model[dataKeys.minAmount], model[dataKeys.maxAmount])) {
                        return false;
                    }
                    if (this.isValueOutsideRange(this.duration, model[dataKeys.minDuration], model[dataKeys.maxDuration])) {
                        return false;
                    }
                    return true;
                });

            return lenders;
        },
        highlightedLenders() {
            return this.filteredLenders.slice(0, 3);
        }
    },
    methods: {
        isValueOutsideRange(value, min, max) {
            return value && (value < min || value > max);
        }
    }
}
</script>