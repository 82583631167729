<template>
    <div v-if="quotes.length > 0" class="bg-light margin-space-3">
        <div class="container content-space-1 content-space-md-2">
            <div class="row justify-content-center">
                <div class="col col-lg-9">
                    <div v-if="$validateText(header)" class="mb-5" v-html="$prismic.asHtml(header)" />
                    <div class="row gy-4">
                        <div v-for="(item, index) in quotes" :key="index" class="col-12 col-md-6">
                            <div class="card h-100 flex-column align-items-center justify-content-center gap-2 p-4 text-dark">
                                <div v-if="item.rating" class="d-flex align-items-center justify-content-center gap-1">
                                    <i 
                                        v-for="num in 5" 
                                        :key="num" 
                                        :class="`bi bi-star${num <= item.rating ? '-fill' : ''} text-warning fs-4`" 
                                    />
                                </div>
                                <div class="text-center rich-text" v-html="$prismic.asHtml(item.quote)" />
                                <p v-if="item.author" class="text-center mb-0">{{ item.author }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        header() {
            return this.slice.primary.header;
        },
        quotes() {
            return this.slice.items
                .map(item => {
                    return {
                        quote: this.$validateText(item.quote) && item.quote,
                        author: this.$validateText(item.quote_by) && item.quote_by,
                        rating: item.rating !== 'hide' && item.rating
                    };
                })
                .filter(item => !!item.quote);
        }
    }
};
</script>