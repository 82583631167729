<template>
    <nuxt-link class="card flex-1 h-100 max-wpx-400 max-wpx-sm-unset text-dark text-primary-hover mx-sm-0" :to="$prismic.linkResolver(document)">
        <div class="row g-0 h-100 ">
            <div :class="{ 'col-sm-4 h-sm-100': !isHighlighted }">
                <prismic-image 
                    class="w-100 h-100 max-hpx-180 object-fit-cover" 
                    :class="{ 
                        'max-hpx-sm-250 max-hpx-lg-300 rounded-top': isHighlighted, 
                        'rounded-top rounded-top-sm-0 rounded-start-sm max-hpx-sm-unset h-unset': !isHighlighted 
                    }" 
                    :img="document.data.image" 
                    :w="imageWidth" 
                    :alt="document.data.image.alt" 
                />
            </div>
            <div class="my-auto" :class="{ 'col-sm-8 p-0': !isHighlighted }">
                <div class="card-body p-3" :class="{ 'p-sm-5': isHighlighted, 'py-sm-2': !isHighlighted }">
                    <h3 class="text-reset card-title fs-5" :class="isHighlighted ? 'fs-sm-3' : 'fs-sm-4'">
                        {{ document.data.title }}
                    </h3>
                    <p v-if="author" class="card-text fs-5" :class="{ 'fs-sm-4': isHighlighted }">
                        {{ `${$translate('written_by', 'Av')} ${$prismic.asText(author.data.name)}` }}
                    </p>
                </div>
            </div>
        </div>
    </nuxt-link>
</template>

<script>
export default {
    props: {
        document: {
            type: Object,
            required: true
        },
        isHighlighted: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        author() {
            return this.$store.state.authors[this.document.data.author.id];
        },
        imageWidth() {
            return this.isHighlighted ? '550' : '400';
        }
    },
};
</script>