<template>
    <div>
        <div class="container content-space-1">
            <h2 class="align-self-start">{{ $prismic.asText(slice.primary.h2) }}</h2>
            <div class="row">
                <div class="card col">
                    <div class="card-body p-2 p-lg-4 d-flex justify-content-center align-items-center">
                        <div>
                            <debt-collection-input-form @changed="updateValues" />
                            <div class="border-top pt-5 mt-5">
                                <interest-rate-selector 
                                    :interest-key-name="interestKeyName"
                                    :interest-title="interestTitle"
                                    :interest-rate="desiredInterestRate"
                                    :max-interest-rate="maxInterestRate"
                                    @changed="(desiredInterestRate) => newDesiredInterestRate = desiredInterestRate"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <debt-collection-overview 
                    :new-desired-interest-rate="newDesiredInterestRate"
                    :amount-sum="amountSum"
                    :previous-monthly-cost-sum="previousMonthlyCostSum"
                />
            </div>
        </div>
    </div>
</template>
<script>
import debtCollectionInputForm from '@/components/calculator/DebtCollectionInputForm.vue';
import interestRateSelector from '@/components/calculator/InterestRateSelector.vue';
import debtCollectionOverview from '@/components/calculator/DebtCollectionOverview.vue';
export default {
    components: {
        debtCollectionInputForm,
        interestRateSelector,
        debtCollectionOverview
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            amountSum: 0,
            previousMonthlyCostSum: 0,
            interestKeyName: 'calculator_desired_interest',
            interestTitle: 'Önskad ränta',
            desiredInterestRate: 12,
            newDesiredInterestRate: 12,
            maxInterestRate: 25
        };
    },
    methods: {
        updateValues(allValues) {
            this.amountSum = allValues.amountSum;
            this.previousMonthlyCostSum = allValues.previousMonthlyCostSum;
        }
    }
};
</script>