<template>
    <div>
        <list-statistics
            v-if="slice.primary.include_statistics_table"
            :list="listWithData" 
            :slice="slice"
            :alternate-data-config="alternateDataConfig"
            class="my-5" 
        />
        <list-comparisons 
            v-if="slice.primary.include_comparisons_table"
            :list="listWithData" 
            :slice="slice"
            :alternate-data-config="alternateDataConfig"
            class="my-5" 
        />
    </div>
</template>

<script>
import ListStatistics from './statistics/index.vue';
import ListComparisons from './comparisons/index.vue';

export default {
    components: {
        ListStatistics,
        ListComparisons
    },
    props: {
        list: {
            type: Array,
            required: true
        },
        slice: {
            type: Object,
            required: true
        },
        alternateDataConfig: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },
    computed: {
        listWithData() {
            return this.list.map(listItem => {
                const lender = Object.assign({}, this.$store.state.lenders[listItem.id]);
                lender.model = new this.$models.Loan(lender);
                return lender;
            });
        }
    }
};
</script>