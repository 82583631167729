<template>
    <div class="col-12 p-md-0">
        <div class="border-light rounded shadow">
            <div class="w-100 d-flex flex-column flex-md-row justify-content-between align-items-center p-2 px-md-4 gap-2 gap-md-4">
                <nuxt-link
                    :to="$prismic.linkResolver(lender)"
                >
                    <prismic-image
                        :img="lender.data.logo"
                        w="140"
                        :alt="lender.data.title"
                    />
                </nuxt-link>
                <ul class="list-unstyled mb-0 w-100 max-wpx-300 w-lg-unset max-wpx-lg-unset d-flex flex-column flex-lg-row flex-grow-1 justify-content-around align-items-stretch align-items-lg-center">
                    <li 
                        v-for="(item, index) in lenderDataConfig" 
                        :key="item.label" 
                        class="d-flex flex-lg-column justify-content-around align-items-center py-1 border-bottom border-bottom-lg-0"
                        :class="{ 'border-bottom-0': index === lenderDataConfig.length - 1 }"
                    >
                        <span class="text-start">
                            {{ item.label }}
                        </span>
                        <span class="flex-grow-1 text-end fw-bold text-dark">
                            {{ item.value }}
                            <i 
                                v-if="item.tooltip" 
                                v-tooltip="item.tooltip"
                                class="bi bi-info-circle-fill ms-1 text-info" 
                            />
                        </span>
                    </li>
                </ul>
                <div class="d-flex flex-column justify-content-center align-items-center text-nowrap mb-2 mb-md-0">
                    <out-link :document="lender" class="btn btn-primary fw-bold" placement="highlighted-lenders" />
                    <span v-if="$isNorway()" class="fs-6">Annonselenke</span>
                </div>
            </div>
            <div v-if="$isSweden() || ($isSpain() && warningInfo)" class="row border-top p-2 g-0 fs-6 fst-italic">
                <warning-triangle />
                <div class="col">
                    <ComplianceWarningText v-if="$isSweden()" class="lh-md fs-7" />
                    <div 
                        v-else-if="$isSpain()" 
                        class="rich-text" 
                        v-html="$prismic.asHtml(warningInfo)"
                    />
                </div>
            </div>
        </div>
        <div class="fs-7 p-2">{{ model.complianceExample }}</div>
    </div>
</template>

<script>
import ComplianceWarningText from '@/components/ComplianceWarningText.vue';
import WarningTriangle from '@/components/WarningTriangle.vue';

export default {
    components: {
        ComplianceWarningText,
        WarningTriangle
    },
    props: {
        lender: {
            type: Object,
            required: true
        },
        alternateDataConfig: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },
    computed: {
        model() {
            return this.lender.model;
        },
        alternateDataKeys() {
            return this.$getAlternateDataKeys(this.alternateDataConfig);
        },
        warningInfo() {
            return this.lender && this.lender.data.warning_info[0]?.text?.length > 0 ? 
                this.lender.data.warning_info : 
                undefined;
        },
        lenderDataConfig() {
            return [
                {
                    label: this.$translate('loan_amount', 'Belopp'),
                    value: this.model[this.alternateDataKeys.amountString],
                    tooltip: this.$getLoanAmountTooltip(
                        this.model, 
                        this.alternateDataConfig.dataType, 
                        this.alternateDataConfig.dataDisplay
                    )
                },
                {
                    label: this.$translate('loan_duration', 'Löptid'),
                    value: this.model[this.alternateDataKeys.getDurationString](),
                    tooltip: this.$getLoanDurationTooltip(
                        this.model, 
                        this.alternateDataConfig.dataType, 
                        this.alternateDataConfig.dataDisplay
                    )
                },
                {
                    label: this.$translate('preamble_effective_interest', 'Effektiv ränta'),
                    value: this.model.effectiveInterestString,
                }
            ];
        }
    }
};
</script>