<template>
    <newsletter-form 
        :background-color="data.backgroundColor" 
        :slice-width="data.sliceWidth" 
        :intro-header="data.introHeader" 
        :header="data.header" 
        :footer-text="data.footerText" 
    />
</template>
<script>
import NewsletterForm from '@/components/newsletter-form/index.vue'; 
export default {
    components: {
        NewsletterForm
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        data() {
            return {
                backgroundColor: this.slice.primary.has_background ? 'bg-soft-primary' : '',
                sliceWidth: this.slice.primary.slice_width,
                introHeader: this.slice.primary.intro_header,
                header: this.slice.primary.header,
                footerText: this.slice.primary.footer_text
            };
        }
    }
};
</script>
