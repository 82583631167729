import LenderFilters from '@/mixins/LenderFilters.js';
import InsertLenders from '@/mixins/InsertLenders.js'; 

export default {
    mixins: [LenderFilters, InsertLenders],
    props: {
        slices: {
            type: Array,
            required: true
        }
    },
    computed: {
        listSlice() {
            return this.slices.find(slice => {
                return slice.slice_type === 'lender_list_automatic_filter' 
                || slice.slice_type === 'lender_list_selected'
                || slice.slice_type === 'lender_list_all'
                || slice.slice_type === 'lender_list_tags'
                || slice.slice_type === 'corporate_lender_list'
                || slice.slice_type === 'corporate_lender_list_all'
                || slice.slice_type === 'credit_card_list_all'
                || slice.slice_type === 'credit_card_list';
            });
        },
        alternateDataKeys() {
            return this.$getAlternateDataKeys(
                {
                    dataType: this.listSlice?.primary.alternate_data,
                    dataDisplay: this.listSlice?.primary.alternate_data_display
                }
            );
        },
        listSliceItems() {
            if (! this.listSlice) {
                return null;
            }

            if (this.listSlice.slice_type === 'lender_list_automatic_filter') {
                return this.lenderListAutomaticFilter;
            }
            
            if (this.listSlice.slice_type === 'lender_list_all') {
                return this.lenderListAll;
            }

            if (this.listSlice.slice_type === 'lender_list_selected') {
                return this.lenderListSelected;
            }

            if (this.listSlice.slice_type === 'lender_list_tags') {
                return this.lenderListTags;
            }
            
            if (this.listSlice.slice_type === 'corporate_lender_list') {
                return this.corporateLenderList;
            }

            if (this.listSlice.slice_type === 'corporate_lender_list_all') {
                return this.corporateLenderListAll;
            }

            if (this.listSlice.slice_type === 'credit_card_list') {
                return this.cardList;
            }

            if (this.listSlice.slice_type === 'credit_card_list_all') {
                return this.cardListAll;
            }
            return null;
        },
        lenderListAutomaticFilter() {
            let items;
            this.copyListSlicePrimaryData();
            items = Object
                .values(this.$store.state.lenders)
                .filter(this.filter);

            return this.insertLenders(this.orderList(items));
        },
        lenderListAll() {
            // eslint-disable-next-line camelcase
            this.slice.primary.insert_list = this.listSlice.primary.insert_list;

            return this.insertLenders(Object.values(this.$store.state.lenders));
        },
        lenderListSelected() {
            let items;
            // eslint-disable-next-line camelcase
            this.slice.primary.insert_list = this.listSlice.primary.insert_list;
            if (this.listSlice.primary.list.id) {
                items = this.$store.state.lists[this.listSlice.primary.list.id].data.items
                    .map(iterator => iterator.item)
                    .filter(item => item.id);
            } 
            else {
                items = Object
                    .values(this.listSlice.items)
                    .map(iterator => iterator.lender)
                    .filter(lender => lender.id);
            }
            if (this.listSlice.primary.sorting === 'manual') {
                return this.insertLenders(items);
            }
            return this.insertLenders(this.orderList(items));
        },
        lenderListTags() {
            let items;
            const tags = this.listSlice.items.map(item => item.tag.trim());
            this.copyListSlicePrimaryData();

            if (tags.length === 0) {
                items = [];
            }
            else {
                items = Object
                    .values(this.$store.state.lenders)
                    .filter(item => this.tagFilter(item, tags));
            }

            return this.insertLenders(this.orderList(items));
        },
        corporateLenderList() {

            if (this.listSlice.primary.list.id) {
                return this.$store.state.lists[this.listSlice.primary.list.id].data.items
                    .map(iterator => iterator.item)
                    .filter(item => item.id);
            } 
            return Object
                .values(this.listSlice.items)
                .map(iterator => iterator.lender)
                .filter(lender => lender.id);
        },
        corporateLenderListAll() {
            return Object.values(this.$store.state.corporateLenders);
        },
        cardList() {
            if (this.listSlice.primary.list.id) {
                return this.$store.state.lists[this.listSlice.primary.list.id].data.items
                    .map(iterator => iterator.item)
                    .filter(item => item.id);
            } 
            return Object
                .values(this.listSlice.items)
                .map(iterator => iterator.item)
                .filter(item => item.id);
        },
        cardListAll() {
            return Object.values(this.$store.state.cards);
        },
    },
    methods: {
        orderList(list) {
            return this.$getOrderedList(list, this.$store.state.lenders, this.$models.Loan);
        },
        copyListSlicePrimaryData() {
            Object.entries(this.listSlice.primary).forEach(([key, value]) => {
                ! this.slice.primary[key] && (this.slice.primary[key] = value); 
            });
        },
        tagFilter(lender, tags) {

            const model = new this.$models.Loan(lender);

            if (! this.filterRedirects(lender, model)) {
                return false;
            }

            const found = Object.values(tags)
                .filter(tag => lender.tags.includes(tag))
                .length; 

            if (this.slice.primary.tag_selector === 'or') {
                return found > 0;
            }

            return found === tags.length;
        }
    }
};

