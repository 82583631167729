<template>
    <div 
        class="container" 
        :class="{'margin-space-b-3': data.backgroundColor}"
    >
        <div :class="[
            {'row justify-content-center': data.sliceWidth !== 'full-width'},
            {'d-md-block gx-md-0 m-md-0': isContainerWidth},
            [data.backgroundColor && isContainerWidth && `${data.backgroundColor} rounded-sm`]]"
        >
            <div :class="isContentWidth ? `col col-lg-9 rounded-sm ${data.backgroundColor}` : ''">
                <div 
                    class="w-md-75 mx-auto text-center content-space-1" 
                    :class="[{'w-lg-50': !isContentWidth}, {'content-space-md-2': data.backgroundColor}]"
                >
                    <div 
                        v-if="loading" 
                        class="spinner-border" 
                        role="status" 
                    />
                    <div v-else-if="success" class="my-3 d-flex flex-column gap-3 justify-content-center">
                        <i class="text-success bi bi-check-circle display-4" />
                        <span class="fw-bold h4">
                            {{ successMessage }} 
                        </span>     
                    </div>
                    <div v-else>
                        <div class="mb-3">
                            <h3 class="text-cap small">
                                {{ data.introHeader }}
                            </h3>
                            <p class="h3">
                                {{ data.header }}
                            </p>
                        </div>
                        <div class="input-card rounded-pill mb-3">
                            <div class="form-floating flex-grow-1">
                                <input 
                                    id="floatingInput" 
                                    v-model="email" 
                                    type="text" 
                                    class="form-control pt-5" 
                                    placeholder="John Doe"
                                >
                                <label 
                                    class="text-start" 
                                    for="floatingInput muted"
                                >
                                    {{ $translate('newsletter_email_label', 'Email') }}
                                </label>
                            </div>
                            <button 
                                class="fw-bold btn btn-primary btn-sm rounded-pill" 
                                @click.prevent="submit"
                            >
                                {{ $translate('newsletter_sign_up_button_text', 'Registrera dig') }}
                            </button>
                        </div>
                        <div v-if="error" class="mb-3 text-danger fw-bold">
                            <span class="bi bi-exclamation-triangle-fill me-2" />
                            {{ errorMessage }}
                        </div>
                        <div 
                            class="small m-0 rich-text" 
                            v-html="data.footerText" 
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            enable: true,
            error: false,
            success: false,
            loading: false,

            email: '',
            errorMessage: this.$translate('newsletter_error_message', 'Ogiltlig email'),
            successMessage: this.$translate('newsletter_success_message', 'Tack för att du prenumerera på vårt nyhetsbrev!')
        };
    },
    computed: {
        isContentWidth() {
            return this.data.sliceWidth === 'content-width';
        },
        isContainerWidth() {
            return this.data.sliceWidth === 'container-width';
        },
        settings() {
            return this.$store.state.settings;
        }
    },
    methods: {
        submit() {
            const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

            if (! this.enable) {
                return;
            }
            if (! this.email.match(validEmailRegex)) {
                this.errorMessage = this.$translate('newsletter_error_message', 'Ogiltlig email');
                this.error = true;
                return;
            }
            this.enable = false;
            this.error = false;
            this.loading = true;
            this.$axios
                .post('https://api.broker.compary.com/users', {
                    email: this.email,
                    guide_id: this.settings.newsletter_form_broker_id,
                    tracking: this.$tracking.getTrackingObject()
                })
                .catch((error) => {
                    this.error = true;
                    this.loading = false;
                    this.enable = true;
                    this.errorMessage = this.$translate('general_error_message', 'Något gick fel, försök igen senare.');
                    console.log(error);
                })
                .then(() => {
                    if (!this.error) {
                        this.enable = true;
                        this.email = '';
                        this.loading = false;
                        this.success = true;
                        this.$event.newsletterSubscribed();
                    }
                });
        },
    },
};
</script>
<style scoped>
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}
</style>
