var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container py-3 content-space-b-1 border-bottom"},[(_vm.showAlternatives)?_c('div',{staticClass:"row"},[_c('h3',[_vm._v(_vm._s(_vm.$translate('preamble_other_alternatives', 'Andra alternativ')))]),_vm._v(" "),_vm._l((_vm.alternatives),function(lender,index){return _c('lender-card',{key:lender.id,attrs:{"lender-id":lender.id,"position":index + 1}})})],2):_c('div',{staticClass:"row d-flex align-items-center"},[_c('div',{staticClass:"col-12 col-lg-6"},[_c('p',{staticClass:"lead"},[_vm._v(_vm._s(_vm.$prismic.asText(_vm.document.data.preamble)))])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-lg-6"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[(_vm.$isSweden())?[_c('table-row',{attrs:{"title":_vm.$translate('loan_amount', 'Belopp'),"value":_vm.model.amountString,"tooltip":_vm.loanAmountTooltip}}),_vm._v(" "),_c('table-row',{attrs:{"title":_vm.$translate('loan_duration', 'Löptid'),"value":_vm.model.getDurationString(_vm.$translate('month_short', 'mån'), _vm.$translate('year_short', 'år')),"tooltip":_vm.durationTooltip}}),_vm._v(" "),_c('table-row',{attrs:{"title":_vm.$translate('interest', 'Ränta'),"value":_vm.model.interestString}}),_vm._v(" "),_c('table-row',{attrs:{"title":_vm.$translate('preamble_effective_interest', 'Effektiv ränta'),"value":_vm.model.effectiveInterestString,"tooltip":! _vm.model.hasFirstTimeOffer 
                                ? null 
                                : _vm.model.getFirstTimeOfferString(
                                    _vm.$translate('first_time_offer_up_to_amount', 'Räntefritt upp till {amount} i {days} dagar för nya kunder'),
                                    _vm.$translate('new_customers_offer_from_amount', 'Räntefritt från {amount} i {days} dagar för nya kunder')
                                )}}),_vm._v(" "),(_vm.model.maxAmount <= 50000)?_c('table-row',{attrs:{"title":_vm.$translate('direct_pay_out', 'Direktutbetalning'),"value":_vm.model.hasDirectPayments24Hours ? _vm.$translate('around_the_clock', 'Dygnet runt') : 
                                _vm.model.hasDirectPayments ? _vm.$translate('direct', 'Direkt') :
                                _vm.$translate('no', 'Nej')}}):_vm._e(),_vm._v(" "),_c('table-row',{attrs:{"title":_vm.$translate('without_uc', 'Utan UC'),"value":_vm.model.takesUC ? _vm.$translate('no', 'Nej') : _vm.$translate('yes', 'Ja'),"tooltip":_vm.$translate('uses', 'Använder') + ' ' + _vm.$getCreditCheckString(_vm.model.creditCheck)}}),_vm._v(" "),_c('table-row',{attrs:{"title":_vm.$translate('payment_remarks', 'Betalningsanmärkning'),"value":_vm.model.acceptsPaymentRemarks ? _vm.$translate('accepted', 'Accepteras') : _vm.$translate('not_accepted', 'Accepteras inte'),"tooltip":_vm.model.acceptsPaymentRemarks && _vm.model.paymentRemarksMaxCount ? ("Max " + (_vm.model.paymentRemarksMaxCount) + " st") : null}}),_vm._v(" "),_c('table-row',{attrs:{"title":_vm.$translate('min_age', 'Åldersgräns'),"value":_vm.model.minAge + ' ' + _vm.$translate('years', 'år')}}),_vm._v(" "),(_vm.model.isBroker)?_c('table-row',{attrs:{"title":_vm.$translate('connected_banks', 'Anslutna banker'),"value":_vm.model.connectedBanks}}):_vm._e()]:(_vm.$isNorway())?[_c('table-row',{attrs:{"title":_vm.$translate('loan_amount', 'Belopp'),"value":_vm.model.amountString,"tooltip":_vm.loanAmountTooltip}}),_vm._v(" "),_c('table-row',{attrs:{"title":_vm.$translate('loan_duration', 'Löptid'),"value":_vm.model.getDurationString(_vm.$translate('month_short', 'mån'), _vm.$translate('year_short', 'år')),"tooltip":_vm.durationTooltip}}),_vm._v(" "),_c('table-row',{attrs:{"title":_vm.$translate('interest', 'Ränta'),"value":_vm.model.interestString}}),_vm._v(" "),_c('table-row',{attrs:{"title":_vm.$translate('preamble_effective_interest', 'Effektiv ränta'),"value":_vm.model.effectiveInterestString,"tooltip":! _vm.model.hasFirstTimeOffer 
                                ? null 
                                : _vm.model.getFirstTimeOfferString(
                                    _vm.$translate('first_time_offer_up_to_amount', 'Räntefritt upp till {amount} i {days} dagar för nya kunder'),
                                    _vm.$translate('new_customers_offer_from_amount', 'Räntefritt från {amount} i {days} dagar för nya kunder')
                                )}}),_vm._v(" "),_c('table-row',{attrs:{"title":"Bank-ID","value":_vm.$formatBoolean(_vm.model.hasBankId)}}),_vm._v(" "),_c('table-row',{attrs:{"title":_vm.$translate('payment_remarks', 'Betalningsanmärkning'),"value":_vm.model.acceptsPaymentRemarks ? _vm.$translate('accepted', 'Accepteras') : _vm.$translate('not_accepted', 'Accepteras inte')}}),_vm._v(" "),_c('table-row',{attrs:{"title":_vm.$translate('min_age', 'Åldersgräns'),"value":_vm.model.minAge + ' ' + _vm.$translate('years', 'år')}})]:(_vm.$isSpain())?[_c('table-row',{attrs:{"title":_vm.$translate('loan_amount', 'Belopp'),"value":_vm.model.amountString,"tooltip":_vm.loanAmountTooltip}}),_vm._v(" "),_c('table-row',{attrs:{"title":_vm.$translate('loan_duration', 'Löptid'),"value":_vm.$getSpanishLoanDuration(_vm.model, _vm.$translate('month_short', 'mån'), _vm.$translate('year_short', 'år')),"tooltip":_vm.durationTooltip}}),_vm._v(" "),(_vm.model.isBroker)?_c('table-row',{attrs:{"title":_vm.$translate('preamble_effective_interest', 'Effektiv ränta'),"value":_vm.model.effectiveInterestString,"tooltip":_vm.$translate('spain_interest_tooltip_on_broker')}}):_c('table-row',{attrs:{"title":_vm.$translate('preamble_effective_interest', 'Effektiv ränta'),"value":_vm.model.effectiveInterestString,"tooltip":! _vm.model.hasFirstTimeOffer 
                                ? null 
                                : _vm.model.getFirstTimeOfferString(
                                    _vm.$translate('first_time_offer_up_to_amount', 'Räntefritt upp till {amount} i {days} dagar för nya kunder'),
                                    _vm.$translate('new_customers_offer_from_amount', 'Räntefritt från {amount} i {days} dagar för nya kunder')
                                )}}),_vm._v(" "),_c('table-row',{attrs:{"title":_vm.$translate('no_salary', 'Utan inkomst från lön'),"value":_vm.$formatBoolean(_vm.model.acceptsNoSalary)}}),_vm._v(" "),_c('table-row',{attrs:{"title":_vm.$translate('with_asnef', 'With ASNEF'),"value":_vm.$formatBoolean(_vm.model.acceptsASNEF)}}),_vm._v(" "),_c('table-row',{attrs:{"title":_vm.$translate('is_broker', 'Är låneförmedlare?'),"value":_vm.$formatBoolean(_vm.model.isBroker)}}),_vm._v(" "),_c('table-row',{attrs:{"title":_vm.$translate('min_age', 'Åldersgräns'),"value":_vm.model.minAge + ' ' + _vm.$translate('years', 'år')}})]:(_vm.$isEstonia())?[_c('table-row',{attrs:{"title":_vm.$translate('loan_amount', 'Belopp'),"value":_vm.model.amountString,"tooltip":_vm.loanAmountTooltip}}),_vm._v(" "),_c('table-row',{attrs:{"title":_vm.$translate('loan_duration', 'Löptid'),"value":_vm.$getSpanishLoanDuration(_vm.model, _vm.$translate('month_short', 'mån'), _vm.$translate('year_short', 'år')),"tooltip":_vm.durationTooltip}}),_vm._v(" "),_c('table-row',{attrs:{"title":_vm.$translate('interest', 'Ränta'),"value":_vm.model.interestString}}),_vm._v(" "),_c('table-row',{attrs:{"title":_vm.$translate('preamble_effective_interest', 'Effektiv ränta'),"value":_vm.model.effectiveInterestString,"tooltip":! _vm.model.hasFirstTimeOffer 
                                ? null 
                                : _vm.model.getFirstTimeOfferString(
                                    _vm.$translate('first_time_offer_up_to_amount', 'Räntefritt upp till {amount} i {days} dagar för nya kunder'),
                                    _vm.$translate('new_customers_offer_from_amount', 'Räntefritt från {amount} i {days} dagar för nya kunder')
                                )}}),_vm._v(" "),_c('table-row',{attrs:{"title":_vm.$translate('arrangement_fee', 'Uppläggningsavgift'),"value":_vm.model.getEstonianSetupFeeString()}}),_vm._v(" "),_c('table-row',{attrs:{"title":_vm.$translate('min_age', 'Åldersgräns'),"value":_vm.model.minAge + ' ' + _vm.$translate('years', 'år')}})]:_vm._e(),_vm._v(" "),(_vm.$isDenmark())?[_c('table-row',{attrs:{"title":_vm.$translate('loan_amount', 'Belopp'),"value":_vm.model.amountString,"tooltip":_vm.loanAmountTooltip}}),_vm._v(" "),_c('table-row',{attrs:{"title":_vm.$translate('loan_duration', 'Löptid'),"value":_vm.model.getDurationString(_vm.$translate('month_short', 'mån'), _vm.$translate('year_short', 'år')),"tooltip":_vm.durationTooltip}}),_vm._v(" "),_c('table-row',{attrs:{"title":_vm.$translate('interest', 'Ränta'),"value":_vm.model.interestString}}),_vm._v(" "),_c('table-row',{attrs:{"title":_vm.$translate('preamble_effective_interest', 'Effektiv ränta'),"value":_vm.model.effectiveInterestString,"tooltip":! _vm.model.hasFirstTimeOffer 
                                ? null 
                                : _vm.model.getFirstTimeOfferString(
                                    _vm.$translate('first_time_offer_up_to_amount', 'Räntefritt upp till {amount} i {days} dagar för nya kunder'),
                                    _vm.$translate('new_customers_offer_from_amount', 'Räntefritt från {amount} i {days} dagar för nya kunder')
                                )}}),_vm._v(" "),(_vm.model.maxAmount <= 50000)?_c('table-row',{attrs:{"title":_vm.$translate('direct_pay_out', 'Direktutbetalning'),"value":_vm.model.hasDirectPayments24Hours ? _vm.$translate('around_the_clock', 'Dygnet runt') : 
                                _vm.model.hasDirectPayments ? _vm.$translate('direct', 'Direkt') :
                                _vm.$translate('no', 'Nej')}}):_vm._e(),_vm._v(" "),_c('table-row',{attrs:{"title":_vm.$translate('min_age', 'Åldersgräns'),"value":_vm.model.minAge + ' ' + _vm.$translate('years', 'år')}}),_vm._v(" "),(_vm.model.isBroker)?_c('table-row',{attrs:{"title":_vm.$translate('connected_banks', 'Anslutna banker'),"value":_vm.model.connectedBanks}}):_vm._e()]:_vm._e()],2)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }