<template>
    <div class="container content-space-b-1">
        <h2>{{ $prismic.asText(slice.primary.h2) }}</h2>
        <div v-if="$validateText(slice.primary.teaser)" v-html="$prismic.asHtml(slice.primary.teaser)" />
        <div class="row">
            <custom-service v-for="(item, index) in customServicesList" :key="item.id" :service="item" :position="index" />
        </div>
    </div>
</template>
<script>
import CustomService from '@/components/CustomService.vue';
export default {
    components: {
        CustomService
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        customServices() {
            return this.$store.state.customServices;
        },
        customServicesList() {
            const list = this.slice.items.map(item => {
                return this.getService(item.custom_service.id);
            });
            return list.filter(item => item !== null);
        },
    },
    methods: {
        getService(id) {
            return this.customServices[id] || null;
        }
    }
};
</script>