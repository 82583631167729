<template>
    <div class="container mb-5">
        <div class="row justify-content-center">
            <div class="col col-lg-9">
                <ul class="list-unstyled text-dark d-flex flex-column gap-4 mb-0">
                    <li
                        v-for="(lender, index) in lenders"
                        :key="index"
                        class="border rounded p-4"
                    >
                        <content-box-content 
                            :document="lender.document" 
                            :image="{ img: lender.document.data.logo, alt: lender.document.data.title, width: '150' }"
                            :left-column-header="lender.columnData.leftColumn.header"
                            :left-column-value="lender.columnData.leftColumn.value"
                            :right-column-header="lender.columnData.rightColumn.header"
                            :right-column-value="lender.columnData.rightColumn.value"
                            :right-column-tooltip="lender.columnData.rightColumn.tooltip"
                        />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import ContentBoxContent from '../ContentBoxItem.vue';
export default {
    components: {
        ContentBoxContent
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        alternateDataConfig() {
            return this.$getAlternateDataConfig(this.slice);
        },
        alternateDataKeys() {
            return this.$getAlternateDataKeys(this.alternateDataConfig);
        },
        lenders() {
            return this.slice.items
                .filter(item => item?.lender?.id)
                .map(item => {
                    const document = this.$store.state.lenders[item.lender.id];
                    const model = new this.$models.Loan(document);

                    const columnData = {
                        leftColumn: {
                            header: this.$translate('interest', 'Ränta'),
                            value: model.interestString
                        },
                        rightColumn: {
                            header: this.$translate('max_loan_amount', 'Max belopp'),
                            value: model[this.alternateDataKeys.maxAmountString],
                            tooltip: this.$getLoanAmountTooltip(model, this.alternateDataConfig.dataType, this.alternateDataConfig.dataDisplay)
                        }
                    };

                    return { 
                        document, 
                        model,
                        columnData
                    };
                });
        }   
    },
};
</script>