<template>
    <div v-if="service" class="col-12 col-md-6 col-lg-4 mb-5">
        <div class="card border">
            <div class="card-header py-1 ps-3 pe-2 bg-dark d-flex justify-content-between align-items-center flex-wrap">
                <span class="text-white fs-6">{{ service.data.title }}</span> 
            </div>
            <card-image :image="service.data.logo" width="150" class="max-hpx-120" />
            <div class="flex-grow-1 d-flex flex-column text-dark fs-6">
                <div v-if="$validateText(service.data.description)" class="flex-grow-1 rich-text mb-2 mx-3" v-html="$prismic.asHtml(service.data.description)" />
                <ul v-if="pros.length" class="d-flex flex-column gap-2 list-unstyled text-dark px-3 p-4 fs-6 m-0">
                    <li 
                        v-for="(pro, index) in pros" 
                        :key="index"
                        class="d-flex align-items-baseline gap-2"
                    >
                        <i class="text-primary bi bi-check-circle-fill" />
                        <span>{{ pro }}</span>
                    </li>
                </ul>
                <div v-if="tableData.length > 0" class="card-body p-3 bg-light fs-6">
                    <table-row 
                        v-for="(data, listIndex) in tableData" 
                        :key="listIndex"
                        :title="data.label" 
                        :value="data.value"
                    />
                </div>
            </div>
            <div v-if="service.data.redirect_enabled" class="card-footer px-0 py-0 border-0 overflow-hidden">
                <div class="row g-0">
                    <div class="bg-primary d-flex justify-content-center">
                        <out-link 
                            :document="service" 
                            class="col-12 btn py-3 text-white fw-bold"
                            placement="list-custom-services"
                            :position="position + 1"
                            :text="$translate('cta_custom_service', 'Ansök nu')"
                        />
                    </div>
                    <span v-if="$isNorway()" class="text-secondary text-center fs-6">Annonslenke</span>
                </div>
            </div>
            <card-information 
                v-if="$validateText(service.data.compliance_example)"
                :compliance-example="service.data.compliance_example" 
                :is-high-cost-credit="false"
            />
        </div>
    </div>
</template>

<script>
import CardImage from '@/components/cards/CardImage.vue';
import CardInformation from '@/components/cards/CardInformation.vue';
import OutLink from '@/components/OutLink.vue';
import TableRow from '@/components/cards/TableRow.vue';
export default {
    components: {
        CardImage,
        OutLink,
        CardInformation,
        TableRow
    },
    props: {
        service: {
            type: Object,
            required: true
        },
        position: {
            type: Number,
            required: true
        }
    },
    computed: {
        tableData() {
            return this.service?.data?.data_rows
                ?.filter(row => this.$validateText(row.label) && this.$validateText(row.value));
        },
        pros() {
            return this.service?.data?.pros
                ?.map(item => item.pro)
                ?.filter(pro => this.$validateText(pro));
        }
    }
}
</script>