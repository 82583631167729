<template>
    <div v-if="author.data" class="d-flex flex-column align-items-start gap-1">
        <div v-for="authorItem in authorEditorConfig" :key="authorItem.translationKey">
            <div v-if="authorItem.author.data" class="d-flex align-items-center gap-1">
                <prismic-image :img="authorItem.author.data.avatar" w="40" h="40" />
                <div>
                    <span>
                        {{ $translate(authorItem.translationKey, authorItem.translationDefault) }}
                        <nuxt-link :to="$prismic.linkResolver(authorItem.author)">
                            <span class="fw-bold text-dark text-decoration-underline text-primary-hover">{{ $prismic.asText(authorItem.author.data.name) }}</span>
                        </nuxt-link>
                    </span>
                </div>
            </div>
        </div>
        <span class="ms-1 pt-2 fst-italic">{{ lastPublicationString }}</span>
    </div>
</template>

<script>
export default {
    props: {
        document: {
            type: Object,
            required: true
        }
    },
    computed: {
        author() {
            return this.document?.data?.author;
        },
        editor() {
            return this.document?.data?.editor;
        },
        lastPublicationDate() {
            return this.document.last_publication_date;
        },
        lastPublicationString() {
            return this.$translate('last_updated', 'Senast uppdaterad {date}')
                .replace('{date}', this.$format.date(this.lastPublicationDate));
        },
        authorEditorConfig() {
            return [
                {
                    author: this.author,
                    translationKey: 'written_by',
                    translationDefault: 'Skriven av',
                },
                {
                    author: this.editor,
                    translationKey: 'edited_by',
                    translationDefault: 'Granskad av',
                }
            ];
        }
    }
}
</script>