<template>
    <div class="container my-4">
        <div class="row justify-content-center">
            <div class="col mx-3 col-lg-9 card table-responsive">
                <lenders-comparision-table 
                    :ids="ids" 
                    :table-type="tableType"
                    :alternate-data-config="alternateDataConfig"
                    class="card-body" 
                />
            </div>
        </div>
    </div>
</template>
<script>
import LendersComparisionTable from '@/components/LendersComparisionTable.vue';
export default {
    components: {
        LendersComparisionTable
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        ids() {
            return this.slice.items
                .filter(iterator => iterator.lender && iterator.lender.id)
                .map(iterator => iterator.lender.id); 
        },
        tableType() {
            return this.slice.primary.table_type || 'small_loan';
        },
        alternateDataConfig() {
            return this.$getAlternateDataConfig(this.slice);
        }
    }
};
</script>