<template>
    <div class="card-footer bg-white fs-6 fst-italic p-3">
        <div v-if="isExpandable" role="button" @click="expanded = true">
            {{ truncatedComplianceExample }}
            <a class="collapsed">Ver más</a>
        </div>
        <div v-else>
            {{ complianceExample }}
        </div>
        <div v-if="$isSweden() || ($isSpain() && warningInfo)" class="row mt-3 pt-3 border-top g-0">
            <warning-triangle />
            <div class="col">
                <ComplianceWarningText v-if="$isSweden()" />
                <div 
                    v-else-if="$isSpain()" 
                    class="rich-text" 
                    v-html="$prismic.asHtml(warningInfo)"
                />
            </div>
        </div>
    </div>
</template>
<script>
import ComplianceWarningText from '@/components/ComplianceWarningText.vue';
import WarningTriangle from '@/components/WarningTriangle.vue';

export default {
    components: {
        ComplianceWarningText,
        WarningTriangle
    },
    props: {
        complianceExample: {
            type: String,
            required: true
        },
        isHighCostCredit: {
            type: Boolean,
            required: true
        },
        lender: {
            type: Object,
            required: false,
            default: null
        }
    },
    data(){
        return {
            expanded: false
        };
    },
    computed: {
        truncatedComplianceExample() {
            return this.complianceExample.slice(0, 125) + '...';
        },
        isExpandable() {
            return this.$isSpain() && this.complianceExample?.length > 150 && !this.expanded;
        },
        warningInfo() {
            return this.lender && this.lender.data.warning_info[0]?.text?.length > 0 ? 
                this.lender.data.warning_info : 
                undefined;
        }
    }
};
</script>