<template>
    <div class="row gy-3">
        <div class="col-12 col-md-3 mb-3 mb-md-0 d-flex justify-content-center align-items-center">
            <nuxt-link 
                v-if="document"
                :to="$prismic.linkResolver(document)" 
                class=" max-wpx-150"
            >
                <PrismicImage
                    :img="image.img"
                    :alt="image.alt"
                    :w="image.width"
                    class="w-100 object-fit-contain"
                />
            </nuxt-link>
            <PrismicImage
                v-else
                :img="image"
                w="150"
                class="w-100 object-fit-contain"
            />
        </div>
        <div class="col-12 col-md-3 d-flex flex-md-column justify-content-center align-items-center gap-3 gap-md-0">
            <span>{{ leftColumnHeader }}</span>
            <span class="fw-bold">
                {{ leftColumnValue }}
                <i 
                    v-if="$validateText(leftColumnTooltip)" 
                    v-tooltip="leftColumnTooltip" 
                    class="bi bi-info-circle-fill ms-1 text-info"
                />
            </span>
        </div>
        <div class="col-12 col-md-3 d-flex flex-md-column justify-content-center align-items-center gap-3 gap-md-0">
            <span>{{ rightColumnHeader }}</span>
            <span class="fw-bold">
                {{ rightColumnValue }}
                <i 
                    v-if="$validateText(rightColumnTooltip)" 
                    v-tooltip="rightColumnTooltip" 
                    class="bi bi-info-circle-fill ms-1 text-info"
                />
            </span>
        </div>
        <div class="col-12 col-md-3 d-flex align-items-center">
            <out-link
                v-if="document"
                class="btn btn-primary w-100" 
                :document="document" 
            />
            <out-link
                v-else-if="coreLink"
                :link="coreLink"
                class="btn btn-primary w-100"
            />
        </div>
    </div>
</template>

<script>
// Used in ContentLenderBox, ContentCardBox and ContentCustomBox
// CustomContentBox is hard coded in the slice, especially used 
// for an easy way of adding Swedish lenders in Norway
export default {
    props: {
        document: {
            type: Object,
            required: false
        },
        coreLink: {
            type: String,
            required: false
        },
        image: {
            type: Object,
            required: true
        },
        leftColumnHeader: {
            type: String,
            required: true
        },
        leftColumnValue: {
            type: String,
            required: true
        },
        leftColumnTooltip: {
            type: String,
            required: false,
            default: ''
        },
        rightColumnHeader: {
            type: String,
            required: true
        },
        rightColumnValue: {
            type: String,
            required: true
        },
        rightColumnTooltip: {
            type: String,
            required: false,
            default: ''
        }
    },        
}
</script>