<template>
    <div class="row">
        <div class="col-12 col-md-7 mb-5">
            <h2 class="text-primary display-6 mb-5">
                {{ bestMatchHeader(lender.data.title) }}
            </h2>
            <p class="mb-0">{{ $prismic.asText(lender.data.preamble) }}</p>
        </div>
        <div class="col-12 col-md-5 mb-5">
            <div class="card rounded position-relative bg-white shadow border">
                <div class="card-header py-1 ps-3 rounded rounded-bottom-0 pe-2 bg-dark w-100">
                    <nuxt-link 
                        :to="$prismic.linkResolver(lender)" 
                        class="text-white fs-6 text-decoration-underline"
                    >
                        {{ lender.data.title }}
                    </nuxt-link>
                </div>
                <img 
                    class="position-absolute top-0 start-100 translate-middle border border-5 border-light avatar avatar-sm avatar-circle"
                    src="https://htmlstream.com/preview/front-v4.2/html/assets/svg/illustrations/top-vendor.svg" 
                >
                <div class="align-self-center card-body">
                    <prismic-image 
                        :img="lender.data.logo" 
                        width="125" 
                        class="object-fit-contain"
                    />
                </div>
                <card-trust-score :trust-score="model.trustpilotScore" :trust-score-count="model.trustpilotRatingCount" disable-empty-spacing />

                <div class="py-3 fs-6 card-body bg-light">
                    <table-row
                        class="border-1" 
                        :title="$translate('loan_amount', 'Belopp')" 
                        :value="model[alternateDataKeys.amountString]" 
                        :tooltip="$getLoanAmountTooltip(model)"
                    />
                    <table-row
                        class="border-1" 
                        :title="$translate('loan_duration', 'Löptid')" 
                        :value="$getDurationString(model, alternateDataConfig.dataType, alternateDataConfig.dataDisplay)"
                    />
                    <table-row
                        v-if="!($isSpain() && model.isBroker)"
                        class="border-1" 
                        :title="$translate('preamble_effective_interest', 'Effektiv ränta')" 
                        :value="model.effectiveInterestString"
                        :tooltip="! model.hasFirstTimeOffer 
                            ? null 
                            : model.getFirstTimeOfferString(
                                $translate('first_time_offer_up_to_amount', 'Räntefritt upp till {amount} i {days} dagar för nya kunder'),
                                $translate('new_customers_offer_from_amount', 'Räntefritt från {amount} i {days} dagar för nya kunder')
                            )"
                    />
                    <template v-if="$isSweden()">
                        <table-row
                            v-if="model.isBroker" 
                            class="border-1"
                            :title="$translate('connected_banks', 'Anslutna banker')" 
                            :value="model.connectedBanks" 
                        />
                        <table-row
                            v-else-if="model.maxAmount <= 50000 || model.isHighCostCredit" 
                            class="border-1" 
                            :title="$translate('direct_pay_out', 'Direktutbetalning')" 
                            :value="model.hasDirectPayments24Hours ? $translate('around_the_clock', 'Dygnet runt') : 
                                model.hasDirectPayments ? $translate('direct', 'Direkt') :
                                $translate('no', 'Nej')" 
                        />
                        <table-row
                            v-else 
                            class="border-1" 
                            :title="$translate('arrangement_fee', 'Uppläggningsavgift')" 
                            :value="model.setupFeeString" 
                        />
                        <table-row
                            class="border-1" 
                            :title="$translate('without_uc', 'Utan UC')" 
                            :value="$formatBoolean(!model.takesUC)"
                            :tooltip="$translate('uses', 'Använder') + ' ' + $getCreditCheckString(model.creditCheck)"
                        />
                        <table-row
                            class="border-1" 
                            :title="$translate('payment_remarks', 'Betalningsanmärkning')" 
                            :value="model.acceptsPaymentRemarks ?
                                $translate('accepted', 'Accepteras') :
                                $translate('not_accepted', 'Accepteras inte')"
                            :tooltip="model.acceptsPaymentRemarks && model.paymentRemarksMaxCount ?
                                `Max ${model.paymentRemarksMaxCount} st` :
                                null" 
                        />
                    </template>
                    <template v-else-if="$isNorway()">
                        <table-row
                            class="border-1" 
                            title="Bank-ID" 
                            :value="$formatBoolean(model.hasBankId)"
                        />
                        <table-row
                            class="border-1" 
                            :title="$translate('payment_remarks', 'Betalningsanmärkning')" 
                            :value="model.acceptsPaymentRemarks ? 
                                $translate('accepted', 'Accepteras') :
                                $translate('not_accepted', 'Accepteras inte')"
                        />
                    </template>
                    <template v-else-if="$isEstonia()">
                        <table-row
                            class="border-1" 
                            :title="$translate('arrangement_fee', 'Uppläggningsavgift')" 
                            :value="model.getEstonianSetupFeeString()"
                        />
                    </template>
                    <template v-else-if="$isSpain()">
                        <table-row
                            v-if="model.isBroker"
                            class="border-1" 
                            :title="$translate('preamble_effective_interest', 'Effektiv ränta')" 
                            :value="model.effectiveInterestString"
                            :tooltip="$translate('spain_interest_tooltip_on_broker')"
                        />
                        <table-row
                            class="border-1" 
                            :title="$translate('spain_loan_type', 'Loan type')" 
                            :value="model.isBroker ?
                                $translate('spain_loan_type_broker', 'Broker') :
                                $translate('spain_loan_type_lender', 'Lender')"
                        />
                        <table-row
                            class="border-1" 
                            :title="$translate('no_salary', 'Utan inkomst från lön')" 
                            :value="$formatBoolean(model.acceptsNoSalary)"
                        />
                        <table-row
                            class="border-1" 
                            :title="$translate('with_asnef', 'With ASNEF')" 
                            :value="$formatBoolean(model.acceptsASNEF)"
                        />
                    </template>
                    <table-row
                        class="border-1" 
                        :title="$translate('min_age', 'Åldersgräns')" 
                        :value="model.minAge + ' ' + $translate('years', 'år')"
                    />
                </div>
                <div class="px-3">
                    <out-link 
                        class="btn btn-sm btn-primary my-3 w-100"
                        :document="lender" 
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TableRow from '@/components/cards/TableRow.vue';
import OutLink from '@/components/OutLink.vue';
import CardTrustScore from '../cards/CardTrustScore.vue';
export default {
    components: {
        TableRow,
        OutLink,
        CardTrustScore
    },
    props: {
        lenderId: {
            type: String,
            required: true
        },
        lenderIndex: {
            type: Number,
            required: true
        },
        alternateDataConfig: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },
    computed: {
        lender() {
            return this.$store.state.lenders[this.lenderId];
        },
        model() {
            return new this.$models.Loan(this.lender);
        },
        alternateDataKeys() {
            return this.$getAlternateDataKeys(this.alternateDataConfig);
        }
    },
    methods: {
        bestMatchHeader(lenderTitle) {
            return this.$translate('loan_wizard_result_best_match', '{lender} är bästa banken utifrån dina kriterier och önskemål')
                .replace('{lender}', lenderTitle);
        }
    },
};
</script>