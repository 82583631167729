<template>
    <div 
        v-if="settings.enable_newsletter_form" 
        :class="[sliceWidth === 'full-width' && backgroundColor]"
    >
        <newsletter-form :data="data" />
    </div>
</template>
<script>
import NewsletterForm from './NewsletterForm.vue';
export default {
    components: {
        NewsletterForm
    },
    props: {
        backgroundColor: {
            type: String,
            required: false,
            default: ''
        },
        sliceWidth: {
            type: String,
            required: false,
            default: ''
        },
        introHeader: {
            type: String,
            required: false,
            default: ''
        },
        header: {
            type: String,
            required: false,
            default: ''
        },
        footerText: {
            type: Array,
            required: false,
            default: Array
        }
    },
    computed: {
        settings() {
            return this.$store.state.settings;
        },
        data() {
            return {
                introHeader: this.introHeader 
                    ? this.introHeader 
                    : this.$translate('newsletter_sign_up_to_our_newsletter', 'Registrera dig för vårt nyhetsbrev'),
                header: this.header 
                    ? this.header 
                    : this.$translate('get_tips_offer_and_much_more', 'Få tips, erbjudanden och mycket mer direkt på mailen.'),
                footerText: this.$validateText(this.footerText) 
                    ? this.$prismic.asHtml(this.footerText) 
                    : this.$translate('newsletter_muted_footer_text', 'Genom att registrera dig accepterar du vår integritetspolicy.'),
                sliceWidth: this.sliceWidth 
                    ? this.sliceWidth 
                    : 'full-width',
                backgroundColor: this.backgroundColor
                    ? this.backgroundColor
                    : ''
            };
        }
    }
};
</script>